<template>
  <div class="qr">
    <img class="background" src="../../assets/image/background.png" alt srcset />
    <header>
      <img src="../../assets/image/logo.png" alt srcset />
    </header>
    <van-divider :style="dividerStyle">支持单位</van-divider>
    <div class="sponsorBox">
      <div class="sponsor_title">钻石赞助商</div>
      <div class="sponsor">
        <div class="sponsor_item diamond">
          <img :src="diamondUrl" />
        </div>
      </div>
      <div class="sponsor_title">铂金赞助商</div>
      <div class="sponsor">
        <div class="sponsor_item platinum">
          <img :src="platinumUrl" />
        </div>
      </div>
      <div class="sponsor_title">黄金赞助商</div>
      <div class="sponsor">
        <div class="sponsor_item gold">
          <!-- <img src="../../assets/image/gold.png" /> -->
          <img :src="goldUrl" />
        </div>
      </div>
      <div class="sponsor_title">白银赞助商</div>
      <div class="sponsor">
        <div class="sponsor_item silver">
          <!-- <img src="../../assets/image/silver.png" /> -->
          <img :src="silverUrl" />
        </div>
        <!-- <div class="sponsor_item silver" v-for="(item,index) in silverList" :key="index">
          <div class="sponsor_img">
            <div>
              <img :src="item.image" alt srcset />
            </div>
          </div>
        </div>-->
      </div>
      <!-- <div class="sponsor_title">支持单位</div>
      <div class="sponsor">
        <div class="sponsor_item diamond">
          <img src="../../assets/image/support.png" />
        </div>
      </div>-->
      <div class="sponsor_title">合作媒体</div>
      <div class="sponsor">
        <div class="sponsor_item gold">
          <!-- <img src="../../assets/image/gold.png" /> -->
          <img :src="mediaUrl" />
        </div>
      </div>
      <div class="sponsor_title">特约财经媒体</div>
      <div class="sponsor">
        <div class="sponsor_item diamond">
          <img :src="specialUrl" />
        </div>
      </div>
    </div>
    <div class="footer-fill"></div>
    <el-footer :index="1" />
  </div>
</template>

<script>
import vueQr from "vue-qr";
import { getConfig } from "../../api/index.js";
import footer from "../../components/footer/footer-tempate.vue";
export default {
  components: {
    "el-footer": footer,
    vueQr
  },
  data() {
    return {
      dividerStyle: {
        color: "#FFFFFF",
        borderColor: "#fff",
        padding: "0 30%",
        "font-size": "16px"
      },
      diamondUrl: "",
      platinumUrl: "",
      goldUrl: "",
      silverUrl: "",
      mediaUrl: "",
      specialUrl: ""
    };
  },
  methods: {
    getConfig() {
      getConfig({ name: "diamond", type: "1" })
        .then(res => {
          this.diamondUrl = res.data;
        })
        .catch(err => {
          console.log(err);
        });
      getConfig({ name: "platinum", type: "1" })
        .then(res => {
          this.platinumUrl = res.data;
        })
        .catch(err => {
          console.log(err);
        });
      getConfig({ name: "gold", type: "1" })
        .then(res => {
          this.goldUrl = res.data;
        })
        .catch(err => {
          console.log(err);
        });
      getConfig({ name: "silver", type: "1" })
        .then(res => {
          this.silverUrl = res.data;
        })
        .catch(err => {
          console.log(err);
        });
      getConfig({ name: "media", type: "1" })
        .then(res => {
          this.mediaUrl = res.data;
        })
        .catch(err => {
          console.log(err);
        });
      getConfig({ name: "special", type: "1" })
        .then(res => {
          this.specialUrl = res.data;
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  created() {
    this.getConfig();
  }
};
</script>

<style lang="less" scoped>
.qr {
  min-height: 100vh;
  // background-image: url("../../assets/image/background.png");
  // background-repeat: no-repeat;
  // background-size: 100% 100%;
  .background {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    position: fixed;
    background-size: 100% 100%;
    z-index: -1;
  }
  header {
    padding: 10px 0;
    width: 80%;
    margin: 0 auto;
    img {
      max-width: 100%;
    }
  }
  .box {
    height: 46px;
  }
  .van-hairline--bottom::after {
    border-bottom-width: 0;
  }
  /deep/ .van-nav-bar .van-icon {
    color: #fff;
  }
  .van-nav-bar {
    z-index: 999;
    background-color: transparent;
  }
  /deep/ .vue-qr {
    width: 50%;
    margin: 10px 25%;
    image {
      text-align: center;
    }
  }
  .sponsorBox {
    width: 90%;
    margin: 0 auto;
    .sponsor_title {
      text-align: center;
      margin: 0 auto;
      font-size: 14px;
      color: #fff;
    }
    .sponsor {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .sponsor_item {
        flex: none;
      }
      .diamond {
        width: 50%;
        margin: 10px 0;
      }
      .platinum {
        width: 45%;
        margin: 10px 0;
      }
      .gold {
        width: 100%;
        margin: 10px 0;
      }
      .silver {
        width: 100%;
        margin: 10px 0;
      }
      img {
        width: 100%;
      }
      // .sponsor_img {
      //   div {
      //     background-color: rgba(255, 255, 255, 0.8);
      //     width: 100%;
      //     height: 100%;
      //     display: flex;
      //     justify-content: center;
      //     align-items: center;
      //     img {
      //       width: 95%;
      //       height: 90%;
      //     }
      //   }
      //   width: 90%;
      //   height: 100%;
      //   padding: 0 5%;
      // }
    }
  }
}
.footer-fill {
  height: 47px;
}
</style>